import { isDefined } from "@clipboard-health/util-ts";
import { useSelectedWorkType } from "@src/app/onboarding/hooks/useSelectedWorkType";
import { isAgentInSignupProcess } from "@src/app/routing/home/isAgentInSignupProcess";
import { useGetAgreements } from "@src/appV2/Agreements/api/useGetAgreements";
import { SIGN_NEW_AGREEMENT_PATH } from "@src/appV2/Agreements/paths";
import { AgreementKind } from "@src/appV2/Agreements/types";
import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { useSession } from "@store/helperHooks";
import { ActionType } from "@store/session";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const TEN_MINUTES_IN_MS = 1000 * 60 * 10;

export function useShowSignNewAgreementPopup() {
  const { isSignup } = useSession();
  const { data: worker } = useGetWorker({
    enabled: !isSignup,
  });

  const dispatch = useDispatch();
  const location = useLocation();

  const workerId = worker?.userId;

  const { isPermanentPlacement } = useSelectedWorkType();

  const lastUpdated = useRef<number>();

  const { data: agreementsToSign, dataUpdatedAt } = useGetAgreements(
    {
      workerId,
      kind: [AgreementKind.INDEPENDENT_CONTRACTOR],
      needsSignature: true,
    },
    {
      enabled: Boolean(workerId),
      refetchInterval: TEN_MINUTES_IN_MS,
    }
  );

  useEffect(() => {
    if (!isDefined(worker) || lastUpdated.current === dataUpdatedAt) {
      return;
    }

    lastUpdated.current = dataUpdatedAt;
    if (isPermanentPlacement === true) {
      dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
    } else if (
      agreementsToSign?.data.length &&
      !isAgentInSignupProcess(worker) &&
      location?.pathname !== `${RootPaths.HOME}/${SIGN_NEW_AGREEMENT_PATH}`
    ) {
      logEvent(APP_V2_APP_EVENTS.SIGN_NEW_AGREEMENT_MODAL_VIEW);
      dispatch({
        type: ActionType.SHOW_IC_AGREEMENT_POPUP,
      });
    } else {
      dispatch({ type: ActionType.HIDE_IC_AGREEMENT_POPUP });
    }
  }, [agreementsToSign, worker, location?.pathname, dataUpdatedAt, dispatch, isPermanentPlacement]);
}
