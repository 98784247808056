import "../styles.scss";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { resyncAccountWithStripe } from "../../api/payments";
import { TabRouterPath } from "../../routing/constant/tabRoute";
import { updateAgentData } from "../../store/session/api";
import { getAuthToken } from "../../superagent";

const OnboardingSuccess: FC = () => {
  const dispatch = useDispatch();
  const worker = useDefinedWorker();
  const history = useHistory();
  const updateWorkerCache = useUpdateWorkerCache();

  useEffect(() => {
    getAuthToken().then((authToken) => {
      const workerId = worker.userId;
      if (authToken && workerId) {
        // no need to wait for successful update
        resyncAccountWithStripe(workerId);

        const updatedFields = {
          agentId: workerId,
          employmentStatus: "1099",
        };
        updateAgentData(updatedFields);
        updateWorkerCache(updatedFields);
      } else {
        console.error(new Error("User is not authenticated"));
      }
    });
  }, [worker.userId, dispatch, updateWorkerCache]);

  const handleFinishingOnboard = useCallback(() => {
    history.replace(TabRouterPath.OPEN_SHIFTS);
  }, [history]);

  return (
    <IonPage>
      <IonContent>
        <IonHeader no-border>
          <IonToolbar>
            <IonTitle size="small" className="header">
              Working with Clipboard
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonText
            color="title"
            class="secondary-heading ion-margin-bottom"
            style={{ fontSize: "16px" }}
          >
            Fast Payments With Clipboard
          </IonText>
          <img
            style={{ width: "45%", maxWidth: "300px", margin: "0.5rem 2.5rem" }}
            src="assets/logo/bunny.png"
            alt="Clipboard logo"
          />
          <p className="space" style={{ fontSize: "13px" }}>
            Woohoo! Now that we sent Stripe the info they need, it’ll take a few days to get your
            account ready to receive funds.
          </p>
          <p className="space" style={{ fontSize: "13px" }}>
            {!worker.isFirstSession &&
              `${"Until then, you’ll continue to be paid using your existing method."} `}
            <IonText color="primary" style={{ fontWeight: "bold" }}>
              We’ll send you a notification once you’re paid through Stripe. You’ll be able to view
              your balances and withdraw funds from the Payroll page in the Account tab
            </IonText>
            .
          </p>
          <div style={{ marginBottom: "20px" }}>
            <IonButton fill="outline" className="footer" onClick={handleFinishingOnboard}>
              Find Open Shifts
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export { OnboardingSuccess };
